/* eslint-disable max-lines-per-function */
import { createTheme } from '@material-ui/core/styles';
import './fonts.css';
import { wp, wp3, wpNew } from './designTokens';
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    tag: {
      p1: any;
      p1Bold: any;
      p2: any;
      p3: any;
    };
  }
  // allow configuration using `createTheme`
  export interface ThemeOptions {
    tag?: {
      p1: any;
      p1Bold: any;
      p2: any;
      p3: any;
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    decorative: {
      blue: {
        light: string;
        main: string;
      };
      green: {
        light: string;
        main: string;
      };
      orange: {
        light: string;
        main: string;
      };
      red: {
        light: string;
        main: string;
      };
      grey: {
        light: string;
        main: string;
      };
      brown: {
        light: string;
        main: string;
      };
      yellow: {
        main: string;
      };
    };

    link: {
      light: string;
      main: string;
      disabled: string;
    };

    // below will be deprecated

    primarycolor: {
      green: string;
      greenLight: string;
      greenHighLight: string;
      black: string;
      primaryBrown: string;
      primaryLightBrown: string;
      primaryLightBrown2: string;
      lightGrey: string;
      purpleHover?: string;
    };

    accent: {
      color1: string;
      color2: string;
      color3: string;
    };

    semantic: {
      error: string;
      warning: string;
      success: string;
    };

    disabled: {
      button: string;
      purple: string;
      blue: string;
      default: string;
      text: string;
      background: string;
    };

    pairings: {
      blue: string;
      lightblue: string;
      darkBlue: string;
      green: string;
      darkGreen: string;
      pink: string;
      brown: string;
      orange: string;
      darkRed: string;
      background1: string;
      text1: string;
      navBackground: string;
    };
  }

  interface PaletteColor {
    disabled?: string;
  }

  interface SimplePaletteColorOptions {
    disabled?: string;
  }

  interface CommonColors {
    lightbrown: string;
    brown: string;
  }

  interface TypeBackground {
    page: string;
    disabled: string;
    highlight: string;
  }

  export interface PaletteOptions {
    decorative?: {
      blue?: {
        light?: string;
        main?: string;
      };
      green?: {
        light?: string;
        main?: string;
      };
      orange?: {
        light?: string;
        main?: string;
      };
      red?: {
        light?: string;
        main?: string;
      };
      grey?: {
        light?: string;
        main?: string;
      };
      brown?: {
        light?: string;
        main?: string;
      };
      yellow?: {
        main?: string;
      };
    };
    link?: {
      light?: string;
      main?: string;
      disabled?: string;
    };
    // below will be deprecated
    primarycolor?: {
      green?: string;
      greenLight?: string;
      greenHighLight?: string;
      black?: string;
      primaryBrown?: string;
      primaryLightBrown?: string;
      primaryLightBrown2?: string;
      lightGrey?: string;
      purpleHover?: string;
    };

    accent?: {
      color1?: string;
      color2?: string;
      color3?: string;
    };

    semantic?: {
      error?: string;
      warning?: string;
      success?: string;
    };

    disabled?: {
      button?: string;
      purple?: string;
      blue?: string;
      default?: string;
      text?: string;
      background?: string;
    };

    pairings?: {
      blue?: string;
      lightblue?: string;
      darkBlue?: string;
      green?: string;
      darkGreen?: string;
      pink?: string;
      brown?: string;
      orange?: string;
      darkRed?: string;
      background1: string;
      text1: string;
      navBackground: string;
    };
  }
}
const customBreakpoints = {
  xs: 0, // Extra small screens
  sm: 600, // mobile screen as confirmed by Ronellia
  md: 768, // Medium screens
  lg: 960, // Large screens
  xl: 1280, // Extra large screens
};

const makeTheme = (theme: any) =>
  createTheme({
    palette: {
      type: 'light',
      ...theme.palette,
      // NOTE all colours below will be deprecated
      primarycolor: {
        green: '#737CE3',
        greenLight: '#EFF0FF',
        black: '#2D313F',
        greenHighLight: '#F8F8FF',
        primaryBrown: '#594646',
        primaryLightBrown2: '#DFD8D8',
        primaryLightBrown: '#B2A7A7',
        lightGrey: '#FBFAF9',
        purpleHover: '#6069D1',
      },
      accent: {
        color1: '#737CE3',
        color2: '#FFFFFF',
        color3: '#006FBB',
      },
      // TODO: RB - we can delete these colours and use theme.palette.{error/success/warning}.main
      semantic: {
        error: '#BF4240',
        success: '#7A9E63',
        warning: '#DD7408',
      },
      disabled: {
        button: '#D0D4FF',
        purple: '#C1C4E2',
        blue: '#80B7DD',
        text: '#E3E5E4',
        default: '#DDDDDD',
        background: '#F1F1F1',
      },
      pairings: {
        blue: '#DEE0F0',
        lightblue: '#BFDBEE',
        darkBlue: '#2B2E52',
        brown: '#5C422B',
        darkGreen: '#39403B',
        darkRed: '#54231C',
        green: '#CFE7D7',
        orange: '#F8E5D3',
        pink: '#F2D9D9',
        background1: '#D3F8F1',
        text1: '#2B535C',
        navBackground: '#F1EDE9',
      },
    },
    tag: {
      p1: {
        fontSize: '15px',
        fontWeight: theme.font.weight.regular,
        lineHeight: '1.6em',
        fontFamily: theme.font.family.secondary,
      },
      p1Bold: {
        fontSize: '15px',
        fontWeight: theme.font.weight.semibold,
        lineHeight: '1.6em',
        fontFamily: theme.font.family.secondary,
      },
      p2: {
        fontSize: '14px',
        fontWeight: theme.font.weight.regular,
        lineHeight: '1.6em',
        fontFamily: theme.font.family.secondary,
      },
      p3: {
        fontSize: '13px',
        fontWeight: theme.font.weight.regular,
        lineHeight: '1.6em',
        fontFamily: theme.font.family.secondary,
      },
    },
    typography: {
      fontFamily: theme.font.family.secondary,
      h1: {
        fontSize: '36px',
        fontWeight: theme.font.weight.bold,
        fontFamily: theme.font.family.primary,
        lineHeight: '1.2em',
      },
      h2: {
        fontSize: '26px',
        fontWeight: theme.font.weight.bold,
        fontFamily: theme.font.family.primary,
        lineHeight: '1.2em',
      },
      h3: {
        fontSize: '22px',
        fontWeight: theme.font.weight.semibold,
        lineHeight: '1.3em',
        fontFamily: theme.font.family.secondary,
      },
      h4: {
        fontSize: '18px',
        fontWeight: theme.font.weight.semibold,
        lineHeight: '1.3em',
        fontFamily: theme.font.family.secondary,
      },
      // body1 and body2 will be deprecated. Please use p1, p2, and p3 instead.
      body1: {
        fontSize: '15px',
        fontWeight: theme.font.weight.regular,
        lineHeight: '1.6em',
      },
      body2: {
        fontSize: '14px',
        fontWeight: theme.font.weight.regular,
        lineHeight: '1.6em',
      },
    },
    overrides: {
      MuiFormControlLabel: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          minWidth: '0',
          '&.wp-link-button': {
            fontSize: '14px',
            lineHeight: '1.5em',
            fontWeight: theme.font.weight.semibold,
            color: theme.palette.link.main,
            boxSizing: 'border-box',
            borderRadius: '6px',
            padding: '1px 2px',
            '&$disabled': {
              color: theme.palette.link.disabled,
            },
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'inherit',
            },
            '&.Mui-focusVisible': {
              outline: '1px solid #006FBB',
              outlineOffset: '1px',
              textDecoration: 'underline',
              backgroundColor: 'inherit',
            },
            '&:active': {
              outline: 'none',
              backgroundColor: theme.palette.link.light,
            },
          },
        },
        text: {
          padding: 0,
        },
      },
      MuiButtonGroup: {
        contained: {
          boxShadow: 'none',
        },
      },
      MuiBreadcrumbs: {
        separator: {
          color: theme.palette.common.black,
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '15px',
          fontWeight: theme.font.weight.semibold,
          lineHeight: '1.5em',
          color: theme.palette.common.black,
          paddingBottom: 4,
          textAlign: 'left',
        },
      },
      MuiOutlinedInput: {
        root: {
          //height: '34px',
          boxSizing: 'border-box',
          borderRadius: '8px',
          background: theme.palette.common.white,
          borderWidth: '1px',

          '&.MuiOutlinedInput-adornedEnd': {
            padding: 0,
          },
          '& .MuiIconButton-root': {
            '&:hover': {
              backgroundColor: 'inherit',
            },
            '& svg': {
              width: '21px',
              color: theme.palette.common.black,
            },
            //  border: `${theme.border.weight.default} ${theme.palette.common.lightbrown}`,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.lightbrown,
          },
          '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
          '&.MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&:not(.Mui-disabled).MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '& .MuiAutocomplete-input': {
            padding: '0 0 0 9px !important',
            textOverflow: 'ellipsis',
          },
        },
        input: {
          padding: '0px 10px',
        },
      },
      MuiInputBase: {
        root: {
          color: theme.palette.common.black,
          '&$disabled': {
            background: theme.palette.background.disabled,
            borderRadius: '6px',
          },
          '& .MuiAutocomplete-inputRoot.Mui-error': {
            borderColor: theme.palette.error.main,
          },
          '& .MuiAutocomplete-inputRoot.Mui-error.Mui-focused': {
            borderColor: theme.palette.error.main,
          },
          '& .MuiAutocomplete-inputRoot.Mui-focused': {
            borderColor: theme.palette.primary.main,
          },
          '&.MuiAutocomplete-inputRoot': {
            paddingLeft: '0 !important',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
          },
        },
        input: {
          height: 34,
        },
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '1.5em',
          '&.Mui-error': {
            color: theme.palette.error.main,
          },
        },
        contained: {
          marginLeft: 0,
        },
      },
      MuiTab: {
        root: {
          minWidth: 'auto',
          '@media (min-width: 600px)': {
            minWidth: 'auto',
          },
          textTransform: 'none',
          '&.Mui-selected': {
            '& .MuiTab-wrapper': {
              fontWeight: theme.font.weight.semibold,
              color: theme.palette.primary.main,
            },
          },
          '& .MuiTab-wrapper': {
            fontWeight: theme.font.weight.regular,
            fontSize: '16px',
            lineHeight: '1.5em',
            color: theme.palette.common.black,
          },
        },
        labelIcon: {
          minHeight: 0,
        },
      },
      MuiTooltip: {
        // TODO: add units from design token
        tooltipPlacementBottom: {
          marginTop: 8,
          marginBottom: 8,
        },
        tooltipPlacementTop: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
      MuiRadio: {
        root: {
          '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
        },
      },
      MuiLinearProgress: {
        root: {
          height: '8px',
          borderRadius: '5px',
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: theme.palette.common.black,
          opacity: '0.5 !important',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '16px 16px 0 16px',
        },
      },
      MuiDialog: {
        root: {
          '& .MuiDialog-paper': {
            maxWidth: 'none',
          },
          '& .MuiDialog-paperWidthXs': {
            width: '479px',
          },
          '& .MuiDialog-paperWidthSm': {
            width: '776px',
          },
          '& .MuiDialog-paperWidthMd': {
            maxWidth: '826px',
          },
          '& .MuiDialog-paperWidthLg': {
            maxWidth: '947px',
          },
        },
        container: {
          border: `${theme.border.weight.default} ${theme.palette.common.brown}`,
          borderRadius: '6px',
        },
      },

      MuiDialogContent: {
        root: {
          padding: '16px',
        },
      },
      MuiDialogActions: {
        root: {
          padding: '16px',
          '& .MuiButtonBase-root': {
            margin: 0,
          },
        },
        spacing: {
          '& :not(:first-child)': {
            margin: '0 0 0 16px',
          },
        },
      },
      // TODO: RB - add theme colours to default and light divider
      // MuiDivider: {
      //   root: {
      //     borderColor: '',
      //   },
      // },
      MuiFormLabel: {
        root: {
          color: theme.palette.text.primary,
        },
      },
      MuiGrid: {
        item: {
          paddingBottom: '16px',
          paddingRight: '16px',
        },
      },
      MuiCard: {
        root: {
          border: `${theme.border.weight.default} ${theme.palette.common.lightbrown}`,
          borderRadius: theme.border.radius.default,
          boxShadow: 'none',
          minWidth: '300px',

          '&.wp-no-shadow-card': {
            border: 'none !important',
            background: 'inherit',

            '& > .MuiCardContent-root': {
              padding: 0,
            },

            '& > .MuiCardHeader-root': {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 16,
            },
          },
          '&.MuiPaper-outlined': {
            border: `${theme.border.weight.default} ${theme.palette.common.lightbrown}`,
            borderRadius: theme.border.radius.default,
          },
        },
      },
      MuiCardHeader: {
        root: {
          paddingBottom: 0,
        },
        action: {
          marginTop: 0,
          marginRight: 0,
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
          color: theme.palette.common.brown,
          '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
          '& .icon': {
            borderRadius: '2px',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
            backgroundColor: theme.palette.common.white,

            '&.error': {
              boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
            },

            '$root.Mui-focusVisible &': {
              outline: `1px auto ${theme.palette.common.lightbrown}`,
              outlineOffset: 2,
            },
            'input:hover ~ &': {
              background: theme.palette.primary.disabled,
            },
            'input:disabled ~ &': {
              background: theme.palette.background.disabled,
            },
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiSelect: {
        root: {
          padding: '6px 8px',
          '&.MuiSelect-select:focus': {
            backgroundColor: theme.palette.common.white,
          },
          '&.MuiSelect-select.MuiInputBase-input': {
            lineHeight: '1.5em',
            height: '34px',
            boxSizing: 'border-box',
            paddingRight: 56,
          },
        },
      },
      MuiSnackbar: {
        root: {
          maxWidth: 600,
        },
        anchorOriginBottomLeft: {
          '@media (min-width: 600px)': {
            left: 8,
            bottom: 8,
            right: 8,
          },
        },
      },
      MuiStepper: {
        root: {
          '& .MuiStep-alternativeLabel:first-child': {
            paddingLeft: 0,
          },
          '& .MuiStep-alternativeLabel:first-child span': {
            alignItems: 'self-start',
            textAlign: 'left',
          },
          '& .MuiStep-alternativeLabel:nth-child(2) .MuiStepConnector-alternativeLabel': {
            left: 'calc(-100% + 45px) !important',
            right: 'calc(50% + 20px)',
          },
          '& .MuiStep-alternativeLabel:last-child': {
            paddingRight: 0,
            '& span': {
              alignItems: 'self-end',
              textAlign: 'right',
            },
            '& .MuiStepConnector-alternativeLabel': {
              left: 'calc(-50% + 20px)',
              right: 45,
            },
          },
        },
      },
      MuiMenu: {
        paper: {
          marginTop: '8px',
        },
      },
      MuiMenuItem: {
        root: {
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)',
          },
        },
      },
      MuiPopover: {
        root: {
          WebkitFontSmoothing: 'antialiased',
        },
      },
      MuiPaper: {
        root: {
          WebkitFontSmoothing: 'antialiased',
        },
        rounded: {
          borderRadius: '6px',
        },
      },
      MuiTableCell: {
        root: {
          padding: '8px',
        },
        head: {
          fontWeight: theme.font.weight.bold,
          backgroundColor: theme.palette.background.paper,
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    breakpoints: {
      values: customBreakpoints,
    },
  });

export const WagepointTheme = () => makeTheme(wp);
export const WagepointNewTheme = () => makeTheme(wpNew);
export const WagepointThree = () => makeTheme(wp3);
