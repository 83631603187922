import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const wpSearchBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    wpSearchBox: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.common.lightbrown,
      borderRadius: '8px',
      display: 'inline-block',
      cursor: 'pointer',
      overflow: 'hidden',
      width: '34px',
      height: '34.5px',
      boxSizing: 'border-box',
      transitionDuration: '.25s',
      '&.expanded': {
        maxWidth: '300px',
        width: '100%',
      },
      '& .wpsb-wrapper': {
        maxWidth: '300px',
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '100%',

        '& .wpsb-search-icon': {
          padding: theme.spacing(1),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },

        '& .wpsb-input': {
          ...theme.typography.body1,
          border: '0px solid transparent',
          outline: '0px solid transparent !important',
          width: 'calc(100% - 30px)',
          verticalAlign: 'top',
          lineHeight: '30px',
          paddingLeft: '5px',
        },

        '& .wpsb-clear-icon': {
          ...theme.typography.h5,
          position: 'absolute',
          right: '0',
          color: theme.palette.decorative.green.main,
          padding: '7px 10px 10px',

          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        },
      },
    },
  })
);
