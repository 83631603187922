import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';

import StepConnector from '@material-ui/core/StepConnector';

export const WpStepperConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.common.lightbrown,
    borderTopWidth: 3,
    borderRadius: 6,
  },
}))(StepConnector);

export const wpStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiStepConnector-completed, & .MuiStepConnector-active': {
        '& .MuiStepConnector-lineHorizontal': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: 3,
      },
    },
    stepLabel: {
      '& .MuiStepLabel-root': {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: theme.spacing(1),
      },
      '& .MuiStepLabel-label': {
        ...theme.tag.p3,
        color: theme.palette.text.primary,
        padding: '0 !important',
        marginTop: '4px',
      },
      '& .MuiStepLabel-iconContainer': {
        padding: 0,
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: '3px',
        borderColor: theme.palette.common.lightbrown,
        borderRadius: '6px',
      },
      '& .MuiStepButton-root': {
        width: 'auto',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
    },
  })
);
