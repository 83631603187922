import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import WpButton from '../wp-button';
import WpIcon from 'components/wp-icon';
import WpToolTip from 'components/wp-tooltip';
import WpTypography from '../wp-typography';
import { useDisableBySelector } from 'utility';
import arrowDownOutlineSvg from 'components/wp-icon/icons/arrow-down-outline';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';
import horizontalMenuSolid from 'components/wp-icon/icons/horizontal-menu-solid';
import verticalMenuOutline from 'components/wp-icon/icons/vertical-menu-outline';
import { wpMenuStyles } from './wpMenuStyles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

export interface IWpMenuOption {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
  id?: string | number;
  tooltip?: string;
}

type OriginsVertical = 'bottom' | 'top' | 'center';

type OriginsHorizontal = 'left' | 'right' | 'center';

type OriginType = {
  vertical?: OriginsVertical;
  horizontal?: OriginsHorizontal;
};

interface IWpMenuProps {
  type: 'Button' | 'MoreActionsButton' | 'MoreActionsOutlineButton' | 'VerticalEllipsis' | 'HorizontalEllipsis';
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'default' | 'inherit';
  /**
   * @deprecated No usages inside WpMenu.
   */
  size?: 'small' | 'default' | 'inherit';
  anchorToEnd?: boolean;
  options: IWpMenuOption[];
  disabled?: boolean;
  label?: string;
  onButtonClick?: (ev: EventListener) => void;
  className?: string;
  transformOrigin?: OriginType;
  anchorOrigin?: OriginType;
}

const WpMenu: React.FC<IWpMenuProps> = ({
  anchorToEnd = true,
  anchorOrigin,
  className = '',
  color,
  disabled = false,
  label,
  onButtonClick,
  options,
  transformOrigin,
  type,
  variant,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = wpMenuStyles();

  const isDisableBySelector = useDisableBySelector(label);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  /*
  on backdrop click of menu, event will be - "backdrop", so type of event will be string
  other cases type of event will be an object
  */
  const handleClose = (option: IWpMenuOption, event: any) => {
    if (typeof event === 'object') event.stopPropagation();
    setAnchorEl(null);
    option?.onClick?.();
  };

  const handleButtonClick = (ev: EventListener) => {
    onButtonClick?.(ev);
  };

  return (
    <div className={clsx(classes.wpMenu, className, { disabled: isDisableBySelector || disabled })}>
      {type === 'MoreActionsButton' && (
        <WpButton variant="text" onClick={handleClick} className="wp-menu-single-btn">
          <WpTypography className="arrow-down-label" variant="p1Bold">
            {label}
          </WpTypography>
          <WpIcon svgIcon={arrowDownOutlineSvg} size={12} />
        </WpButton>
      )}
      {type === 'VerticalEllipsis' && (
        <IconButton
          aria-label="more"
          aria-controls="wp_menu_dropdown"
          aria-haspopup="true"
          onClick={handleClick}
          className={clsx('wp-menu-vertical-ellipsis', { [classes.outlinedEllipsis]: variant === 'outlined' })}
        >
          <WpIcon svgIcon={verticalMenuOutline} color={variant === 'outlined' ? 'primary' : 'brown'} size={18} />
        </IconButton>
      )}
      {type === 'HorizontalEllipsis' && (
        <IconButton
          aria-label="more"
          aria-controls="wp_menu_dropdown"
          aria-haspopup="true"
          onClick={handleClick}
          className="wp-menu-horizontal-ellipsis"
        >
          <WpIcon svgIcon={horizontalMenuSolid} color="brown" size={18} />
        </IconButton>
      )}
      {type === 'Button' && (
        <div className={classes.menuButton}>
          <WpButton variant={variant} color={color} onClick={handleButtonClick} className="wp-menu-btn part1">
            {label}
          </WpButton>
          <WpButton
            variant={variant}
            color={color}
            onClick={handleClick}
            className={clsx('wp-menu-btn', 'part2')}
            aria-controls="wp_menu_dropdown"
            aria-haspopup="true"
            aria-label="menu"
            isVertical={false}
          >
            <WpIcon
              svgIcon={caretDownSolid}
              size={12}
              color={variant === 'outlined' ? 'primary' : variant === 'text' ? 'link' : 'white'}
            />
          </WpButton>
        </div>
      )}
      {type === 'MoreActionsOutlineButton' && (
        <WpButton variant={variant} color={color} onClick={handleClick} className="wp-menu-btn">
          {label}

          <Box ml={0.5}>
            <WpIcon
              svgIcon={caretDownSolid}
              size={12}
              color={variant === 'outlined' ? 'primary' : variant === 'text' ? 'link' : 'white'}
            />
          </Box>
        </WpButton>
      )}
      <Menu
        elevation={0}
        id="wp_menu_dropdown"
        className={classes.wpMenuDropdown}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorToEnd ? 'right' : 'left',
          ...anchorOrigin,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorToEnd ? 'right' : 'left',
          ...transformOrigin,
        }}
        PaperProps={{ variant: 'outlined' }}
      >
        {options.map((option, index) =>
          option.tooltip ? (
            <div key={index}>
              <WpToolTip tooltype={2} title={option.tooltip} arrow>
                <span>
                  <MenuItem
                    key={`wp_menu_item_${index}`}
                    onClick={(event) => handleClose(option, event)}
                    className={clsx({ disabled: option.disabled, hidden: option.hidden })}
                  >
                    {option.label}
                  </MenuItem>
                </span>
              </WpToolTip>
            </div>
          ) : (
            <MenuItem
              key={`wp_menu_item_${index}`}
              onClick={(event) => handleClose(option, event)}
              className={clsx({ disabled: option.disabled, hidden: option.hidden })}
            >
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default WpMenu;
